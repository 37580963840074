<template>
    <div>
        <div class="productWrod" v-if="isKeyshow">
            <div class="productClass df"  :key="index">
                <div class="co9 fontS16 mar20">品牌：</div>
                <div class="df flexW flex1">
                    <template v-for="(item, index) in goodsBrandList"  :key="index">
                        <div class=" fontS16 items marB2 cur" :class="activeBrand==index?'themC':'co3'"  v-if="flag?true:index<10" @click="activeBrand=activeBrand==index?null:index;getGoodsList()"   >{{ item.name }}</div>
                    </template>
                </div>
                <div class=""  @click="flag=!flag" v-if="goodsBrandList.length>10">
                    <div class="flex fontS16 co9 cur" v-if="!flag" >
                        <div>展开</div><img src="@/assets/img/icon_more1.png" alt=""
                            style="width: 14px;height: 14px;margin-left: 6px;">
                    </div>
                    <div class="flex fontS16 co9 cur" v-else>
                        <div class="line"></div>
                        <div>收起</div><img src="@/assets/img/icon_more2.png" alt=""
                            style="width: 14px;height: 14px;margin-left: 6px;">
                    </div>
                </div>
            </div>
            <div class="productClass df" v-if="!props.isShowTypea">
                <div class="co9 fontS16 mar20">渠道：</div>
                <div class="df flexW flex1">
                   <div class=" fontS16  marB2 cur" @click="typea=typea==1?null:1;getGoodsList()" style="margin-right: 40px;" :style="{color:typea==1?'#D22D25': '#333'}">日本直邮</div>
                   <div class=" fontS16  marB2 cur" @click="typea=typea==2?null:2;getGoodsList()" :style="{color:typea==2?'#D22D25': '#333'}">保税发货</div>
                </div>
            </div>
          
            <template v-for="item in 3" :key="item">
            <template v-if="classList && (item==1 ||  (item==2 && indexClassOne || indexClassOne==0) ||  (item==3 && indexClassTwo || indexClassTwo==0))">
                <div class="productClass boxJ df"  > 
                    <div class="co9 fontS16 mar20" >{{item==1?'类目：':item==2?'二级：':'三级：'}}</div>
                <div class="df flexW flex1">
                    <template v-if="item==1">
                        <div class=" fontS16  marB2 cur"  :style="{color:index==indexClassOne?'#D22D25': '#333'}" style="margin-right: 40px;" v-for="(item,index) in classList" :key="item.id" @click="indexClassOne=index;indexClassTwo=null;indexClassThree=null;getGoodsList(item.id)" >{{ item.name }}</div>
                    </template>
                    <template v-if="item==2 && (indexClassOne || indexClassOne==0)" >
                        <div class=" fontS16  marB2 cur"   :style="{color:index==indexClassTwo?'#D22D25': '#333'}" style="margin-right: 40px;" v-for="(item,index) in classList[indexClassOne].children" :key="item.id" @click="indexClassTwo=index;indexClassThree=null;getGoodsList(item.id)" >{{ item.name }}</div>
                    </template>
                    <template v-if="item==3 && (indexClassTwo  || indexClassTwo==0)" >
                        <div class="fontS16  marB2 cur"  :style="{color:index==indexClassThree?'#D22D25': '#333'}" v-for="(item,index) in  classList[indexClassOne].children[indexClassTwo].children" :key="item.id" style="margin-right: 40px;" @click="indexClassThree=index;getGoodsList(item.id)" >{{ item.name }}</div>
                    </template>
                </div>
            </div>
            </template>
        </template>
            
        </div>
        <div class="filterWrap flexJBC mt24">
            <!-- :color="index == 2 && data.activeNav == index ? '#25459C' : '#666'" -->
            <div class="filterLeft fontS16 co3 flex">
                <div class="mr60 cur flex" v-for="(item, index) in filterData" :key="index" 
                    :style="{color:index==activeFilterData?'#D22D25': '#333'}" @click="filterDataClick(index)">
                    <div>{{ item.name }}</div>
                    <div class="flex flexCl" v-if="index != 0" style="margin-left: 5px;margin-bottom: -4px;">
                        <el-icon size="10"  :color="desc && index==activeFilterData?'#D22D25': '#666'">
                            <ArrowUp />
                        </el-icon>
                        <el-icon size="10"   :color="!desc && index==activeFilterData?'#D22D25': '#666'">
                            <ArrowDown />
                        </el-icon>
                    </div>
                </div>
            </div>
            <div class="filterRight fontS16 CO3">共<span class="themC">{{ props.totalGoods }}</span>款商品</div>
        </div>
    </div>
</template>

<script setup>
import { ArrowUp, ArrowDown } from "@element-plus/icons";
import { reactive, ref, onMounted,defineEmits,defineProps,watch,defineExpose } from 'vue'

import { getGoodsBrand ,getCateTree} from '@/utils/api'

// 综合筛选
const activeFilterData = ref(0)
const orderArr=ref(['multiple','desc'])
const desc =ref(true) //由高到底
const filterData = ref([{ name: '综合', }, { name: '价格' }, { name: '毛利' }, { name: '销量' }])
const filterDataClick=(index)=>{
    if(activeFilterData.value==index){
        desc.value=!desc.value
    }else{
        activeFilterData.value=index;
        desc.value=false
    }
    if(activeFilterData.value==0){
        orderArr.value=['multiple',desc.value?'asc':'desc']
    } else if(activeFilterData.value==1){
        orderArr.value=['retail_price',desc.value?'asc':'desc']
    }else if(activeFilterData.value==2){
        orderArr.value=['gross_profit',desc.value?'asc':'desc']
    }else if(activeFilterData.value==3){
        orderArr.value=['turnovers',desc.value?'asc':'desc']
    }
    getGoodsList()
}
onMounted(() => {
    getGoodsBrandList()
    getCateTreeList()
})
const props =defineProps({
    totalGoods:{ //总数量
        default:0 
    },
    isShowTypea:{
        default:false
    },
    isKeyshow:{
        default:true
    }
})


const typea=ref(null) //1是日本直邮  2是保税发货

// 品牌
const activeBrand=ref(null)
const flag = ref(false)
const goodsBrandList = ref([])
const getGoodsBrandList = () => {
    getGoodsBrand({}).then(res => {
        if (res.code == 200) {
            goodsBrandList.value=res.data
        }
    })
}

// 分类
const indexClassOne=ref(null)//当前选中的1级分类
const indexClassTwo=ref(null)//当前选中的2级分类
const indexClassThree=ref(null)//当前选中的3级分类
const classList=ref('') 
const getCateTreeList=()=>{
  getCateTree({}).then(res=>{
    if(res.code==200){
      classList.value=res.data
    }
  })
}

const goodsCategoryId=ref('')
const changeIndexClass=(index,index1,index2,val)=>{
    indexClassOne.value=index
    indexClassTwo.value=index1
    indexClassThree.value=index2
  goodsCategoryId.value=val.id//3级id
  
    getGoodsList()

}
defineExpose({ changeIndexClass })




 
const emit = defineEmits(['getGoodsList'])
const getGoodsList = (id)=>{
    if(id!=undefined&&id!=''){
        goodsCategoryId.value=id
    }
    let a = {
        goods_brand_id: goodsBrandList.value[activeBrand.value]?goodsBrandList.value[activeBrand.value].id : '',
        // goods_category_id:indexClassThree.value || indexClassThree.value==0?
        // classList.value [indexClassOne.value].children[indexClassTwo.value].children[indexClassThree.value].id :'',
        is_direct:props.isShowTypea? props.isShowTypea==1?1:'': typea.value==1?1:''  ,
        is_bonded:props.isShowTypea? props.isShowTypea==2?1:'' : typea.value==2?1:'' ,
        goods_category_id:goodsCategoryId.value,
        //  order:[{multiple:activeFilterData.value==1?(desc.value?'asc':'desc') :'',retail_price:activeFilterData.value==2?(desc.value?'asc':'desc') :'',gross_profit:activeFilterData.value==3?(desc.value?'asc':'desc') :'',turnovers:activeFilterData.value==4?(desc.value?'asc':'desc') :''}]
         order:orderArr.value,
         page:1
        
    }
    emit('getGoodsList',a)
}

watch(()=>props.isShowTypea, (newVal,old)=>{
    console.log(newVal,'newVal')
    indexClassOne.value=null
        indexClassTwo.value=null
        indexClassThree.value=null
        activeBrand.value=null
        typea.value=null
        activeFilterData.value=0
    // if(newVal==1||newVal==2||newVal==3){
       
    // }
},{immediate:true,})
</script>

<style lang="scss" scoped>
.boxJ{
    // height: 66px;
    // line-height: 66px;
    // padding: 0 !important;
    min-height: 66px;
    /* line-height: 66px; */
    padding: 24px 0 0 0 !important;
    box-sizing: border-box;
}
.filterWrap {
    padding: 0 24px;
    width: 1168px;
    height: 68px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 2px 2px 20px 0px #66666614;
    box-sizing: border-box;

}

.productWrod {
    width: 1168px;
    align-items: flex-start;
    background: #fff;
    border-radius: 12px;

    .productClass {
        margin: 0 24px;
        padding: 24px 0 0;
        border-top: dashed 1.5px #BFBFBF;
        .items {
            margin-right: 20px;
            width: 175px;
        }
        .items:last-child{
            margin-right:0px;
        }
    }

    .productClass:nth-of-type(1) {
        border-top: none 
    }

    .line {
        width: 3px;
        height: 16px;
        background: #D9D9D9;
        border-radius: 3px;
        margin-right: 6px;
        margin-top: 2px;
    }
}</style>
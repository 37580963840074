<template>
    <el-dialog v-model="wechatShow" title="" width="567"  :show-close="true">
        <div class="fontS20 text-c co3">  {{title}}  </div>
        <div class="imgWeChat">
            <img :src="imgShow" alt="" style="width: 100%" />
        </div>
       <div class="co6 fontS16 text-c">{{name}}</div>
    </el-dialog>
</template>

<script setup>
 import { reactive, ref,defineProps,defineEmits,defineModel} from 'vue'
 const wechatShow=defineModel()
const props=defineProps({
    title:{
        default:'请扫码添加下方企业微信账号，为您提供优质服务',
        type:String
    },
    imgShow:{
        default:'',
        type:String
    },
    name:{
        default:'B2B订单对接人-王祺',
        type:String
    }
})
// const emit=defineEmits[]
const wechatShowFn=()=>{
    emit('update:wechatShow')
}
</script>

<style>
.imgWeChat{width: 200px;height: 200px;overflow: hidden;margin:30px auto 20px;background: rebeccapurple;}
</style>
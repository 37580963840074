import { createStore } from 'vuex'

export default createStore({
  state: {
    // 购物车
    allCartNum:0,
    userInfo:{
      nickname:'',
      avatar:'',
      auth_status:'',
      user_role:'',
      metaData:'',
    },
    goodskeyword:'',
    routerPath:'',
    userConfirm:-1
  },
  getters: {
  },
  mutations: {
    // 购物车
    updateAllCartNum (state, payload) {
      state.allCartNum = payload
    },
    // 用户
    updateUser (state, payload) {
      state.userInfo = payload
    },
    // 搜索
    updateKeywordr (state, payload) {
      state.goodskeyword = payload
    },
    // 确认搜索
    updateUserConfirm (state, payload) {
      state.userConfirm = payload

    },
    // 路由
    updateRouterPath (state, payload) {
      state.routerPath = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
